import { NzConfig } from "ng-zorro-antd/core/config";

export const CompanyZorroConfig: NzConfig = {
    pageHeader: {
        nzGhost: false
    },
    space: {
        nzSize: 12,
    },
    descriptions: {
        nzSize: 'small'
    }
}