import { Routes } from "@angular/router";
import { CustomerTokenActivationGuard } from "@shared/modules/customer";

export const AppRoutes: Routes = [
    {
        path: ':token',
        pathMatch: 'prefix',
        canActivate: [CustomerTokenActivationGuard('token')],
        loadChildren: () => import('./pages/public/public.routes')
    },
    { path: '**', pathMatch: 'full', loadComponent: () => import('./pages/error/error.page') }
];