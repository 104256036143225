import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import '@shared/config/mingo.config';
import { provideNgxMask } from 'ngx-mask';

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [provideNgxMask()]
}).catch(err => console.error(err));
