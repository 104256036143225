import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CwtCoreModule } from '@cawita/core-front';
import { CwtZorroModule } from '@cawita/core-front/zorro';
import { environment } from '@customer-env';
import { QuillConfig } from '@shared/config/quill.config';
import { SharedCustomerModule } from '@shared/modules/customer';
import { RecaptchaModule } from '@shared/widgets/features/common/recaptcha';
import { QuillModule } from 'ngx-quill';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { CompanyCoreConfig } from './configs/core-front.config';
import { CompanyCustomerConfig } from './configs/customer.config';
import { CompanyZorroConfig } from './configs/ng-zorro.config';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedCustomerModule.forRoot(CompanyCustomerConfig),
    CwtCoreModule.forRoot(CompanyCoreConfig),
    QuillModule.forRoot(QuillConfig),
    CwtZorroModule.forRoot(CompanyZorroConfig),
    RecaptchaModule.forRoot(environment.recaptchaSiteKey),
    RouterModule.forRoot(AppRoutes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
